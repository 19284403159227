import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import Helmet from 'react-helmet';
import { Col, Row, Grid } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import classnames from 'classnames';

// import { tRouteLocale } from '../../browser/createRoutes';
import { myStaySend } from './actions';
import Header from '../app/Header.react';
import Footer from '../app/Footer.react';
import Notifications from '../app/Notifications.react';
import { toggleSpinner } from '../../common/app/actions';
import FieldGroup from '../app/components/FieldGroup';
import CheckboxField from '../app/components/CheckboxField';
import {CAPTCHA_KEY} from "../browserConfig";

const initialState = {
  finished: false,
  submitted: false,
  errors: {},
  firstname: '',
  surname: '',
	phone: '',
	email: '',
  interest: '',
	company: '',
	comapnyIc: '',
  note: '',
  captcha: null,
};

class MyStayFormContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    toggleSpinner: PropTypes.func,
    type: PropTypes.oneOf(['mystay', 'rp', 'rp-client', 'cz1', 'cz2']),
  };

  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState() {
    const { p } = this.props;
    return {
      ...initialState,
      phone: p.t('phoneprefix'),
    };
  }

  handleSubmit = (e) => {
    const { p } = this.props;
    e.preventDefault();
    const errors = this.validate();

    this.setState({ errors, submitted: true, });

    if (Object.keys(errors).length) {
      console.log('errors', errors);
      return;
    }

    let exData = {};

    if (this.props.type === 'mystay') {
      exData = {
        interest: this.state.interest,
      };
    } else if(this.props.type === 'cz1') {
      exData = {
        company: this.state.company,
      };
    } else if(this.props.type === 'rp-client') {
      exData = {
        company: this.state.company,
        note: this.state.note,
      };
    } else if(this.props.type === 'cz2') {
      exData = {
        ic: this.state.ic,
        email: this.state.email,
      };
    }

    const data =  {
      type: this.props.type,
      name: this.state.firstname,
      surname: this.state.surname,
      phone: this.state.phone,
      token: this.state.captcha,
      ...exData,
    };

    this.props.myStaySend(data).then(act => {
      if (act.error) {
        this.setState({
          errors: {
            submit: p.t('formsenderror'),
          },
      });
        return;
      }

      this.setState({ finished: true });
    });
  }

  handleReset = () => {
    this.setState(this.getInitialState());
  }

  handleChange = (updt) => {
    this.setState(updt, () => {
      if (this.state.submitted) {
        const errors = this.validate();
        this.setState({ errors });
      }
    });
  }

  validate() {
    // const { p } = this.props;
    const values = this.state;
    const errors = {};

    if (values.firstname.length < 1) {
      errors.firstname = 'Pole je povinné';
    }

    if (values.surname.length < 1) {
      errors.surname = 'Pole je povinné';
    }

    if (values.phone.length < 9) {
      errors.phone = 'Pole je povinné';
    }

    if (this.props.type === 'mystay' && !values.interest) {
      errors.interest = 'Pole je povinné';
    }

    if ((this.props.type === 'cz1' || this.props.type === 'rp-client')&& !values.company) {
      errors.company = 'Pole je povinné';
		}

    if (this.props.type === 'cz2' && !values.email) {
      errors.email = 'Pole je povinné';
		}

    if (this.props.type === 'cz2' && !values.ic) {
      errors.ic = 'Pole je povinné';
    }

    if (this.props.type === 'rp-client' && !values.note) {
      errors.note = 'Pole je povinné';
    }

    if (!values.captcha) {
      errors.captcha = 'Vyplňte prosím captcha';
    }

    if (!values.gdprterms) {
      errors.gdprterms = 'Pole je povinné';
    }

    return errors;
  }

  renderFinished() {
    const { p } = this.props;
    return (
      <section className="text-center" style={{minHeight: '50vh', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <h3 className="h2">{p.t('thankyoutext1')}</h3>
        <h3 className="h2">{p.t('thankyoutext2')}</h3>
      </section>
    );
  }

  renderForm() {
    const { p, type } = this.props;

    const interestOptions = ['Gusto Kartu - Rekreačný poukaz', 'MyStay', 'Obidvoje'].map(txt => ({ value: txt, label: txt }));
    const showInterests = type === 'mystay';
    const showCompany = type === 'cz1' || type === 'rp-client';
		const showNote = type === 'rp-client';
		const showEmail = type === 'cz2';
		const showCompanyIc = type === 'cz2';
		const txtPrefix = ['cz2'].includes(type) ? type : '';

    return (
      <section className="section-content">
      <form
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
      >
      <Grid>
        <Row>
          <Col xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{paddingBottom: 30, maxWidth: 550}}>
            <h3 className="h2">
              {txtPrefix ? p.t(`${txtPrefix}.fillinform1`) : p.t('fillinform1')}
              {(type === 'rp' || type === 'rp-client' || type === 'cz1') &&
              <>
                <br/>
                {p.t('fillinform1Rp')}
              </>}
            </h3>
            <p>{txtPrefix ? p.t(`${txtPrefix}.fillinform2`) : p.t('fillinform2')}</p>
            <FieldGroup
              name="firstname"
              label={p.t("firstname")}
              value={this.state.firstname}
              onChange={val => this.handleChange({firstname: val})}
              error={this.state.errors.firstname}
            />
            <FieldGroup
              name="surname"
              label={p.t("surname")}
              value={this.state.surname}
              onChange={val => this.handleChange({surname: val})}
              error={this.state.errors.surname}
            />
            {showInterests && <div className={classnames("form-group", { "has-error": this.state.errors.interest })}>
              <label className="control-label-floating active">{p.t('interest')}</label>
              <Select
                className="Select-form"
                resetValue=""
                options={interestOptions}
                searchable={false}
                placeholder={p.t('select')}
                value={this.state.interest}
                onChange={value => this.handleChange({ interest: value && value.value })}
                id="interest" />
              {this.state.errors.interest && <div className="help-block">{this.state.errors.interest}</div>}
            </div>}
            <FieldGroup
              name="phone"
              label={p.t("phone")}
              maxLength={20}
              value={this.state.phone}
              onChange={val => this.handleChange({phone: val})}
              error={this.state.errors.phone}
            />
            {showEmail && <FieldGroup
              name="email"
              label={p.t("email")}
              maxLength={256}
              value={this.state.email}
              onChange={val => this.handleChange({email: val})}
              error={this.state.errors.email}
            />}
            {showCompany && <FieldGroup
              name="company"
              label={p.t("company")}
              value={this.state.company}
              onChange={val => this.handleChange({company: val})}
              error={this.state.errors.company}
						/>}
            {showCompanyIc && <FieldGroup
              name="ic"
              label={p.t("ic")}
              maxLength={8}
              value={this.state.ic}
              onChange={val => this.handleChange({ic: val})}
              error={this.state.errors.ic}
            />}
            {showNote && <FieldGroup
              name="note"
              type="textarea"
              rows={3}
              label={p.t("note")}
              value={this.state.note}
              onChange={val => this.handleChange({note: val})}
              error={this.state.errors.note}
            />}
            <CheckboxField
              name="gdprterms"
              value={this.state.gdprterms}
              onChange={val => this.handleChange({gdprterms: val})}
              error={this.state.errors.gdprterms}
            >
              <span>
                {p.t('gdprtext1')}
                <a href={p.t('gdprtermslink')} rel="noopener noreferrer" target="_blank" className="link">
                  {p.t('gdprtextlink')}
                </a>
                {p.t('gdprtext2')}
              </span>
            </CheckboxField>
            <div className={this.state.errors.captcha && "has-error"} style={{display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', flexDirection: 'column'}}>
              <ReCAPTCHA
                sitekey={CAPTCHA_KEY}
                onChange={val => this.setState({captcha: val})}
              />
              {this.state.errors.captcha && <div className="help-block">{this.state.errors.captcha}</div>}
            </div>
            {this.state.errors.submit && <div className="has-error"><h3 className="text-center help-block">{this.state.errors.submit}</h3></div>}
          </div>
          </Col>
        </Row>
        <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />
        <Row>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-around'}}>
            <button type="reset" className="btn btn-accent btn-accent-outline btn-lg btn-icon-left">
              <span className="glyphicon glyphicon-remove" aria-hidden="true" />
              {p.t('reset')}
            </button>
            <button type="submit" className="btn btn-accent btn-lg">
              <span className="btn-icon-right">
                {p.t('submit')} <span className="glyphicon glyphicon-send" aria-hidden="true" />
              </span>
            </button>
          </Col>
        </Row>
      </Grid>
      </form>
    </section>
    );
  }

  render() {
    const { location, p, currentLocale } = this.props;

    return (
      <div className="site site-fixed-header">
        <Helmet title={p.t('mystayquestionnaire')}>
          {<meta name="description" content={p.t('metadescription')} />}
        </Helmet>
        <Notifications />
        <Header title={p.t('mystayquestionnaire')} p={p} location={location} currentLocale={currentLocale} noLink>
          <li role="separator" className="divider" />
          <li>
            <a href={p.t('headerSignInUrl')}>
              <span
                className="glyphicon glyphicon-user offset-right hidden-xs hidden-sm"
                aria-hidden="true"
              />
              <span className="hidden-md">{p.t('headerSignIn')}</span>
            </a>
          </li>
        </Header>
        <main id="content" role="main" className="site-content bg-gray-lighter">
          {this.state.finished ? this.renderFinished() : this.renderForm()}
        </main>
        <Footer p={p} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.mystay,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'mystay' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
});

const mapDispatchToProps = ({
  toggleSpinner, myStaySend
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStayFormContainer);
