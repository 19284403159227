import autodetectEnv from './configAutodetect';

const env = autodetectEnv();

// 'production' = production version (no logs), 'develop' = development version
// (still built with gulp to-html, logs available)
export const ENVIRONMENT = env.envType;
export const DEVELOPMENT = env.envType === 'develop';
export const API_URL = `${env.apiUrl}/`; // with slash as the last character. information available on http://docs.cessystem.apiary.io/
export const API_VERSION = '1.0'; // information available on http://docs.cessystem.apiary.io/
export const WEB_URL = `${env.webUrl}/`; // with slash as the last character.
export const APP_VERSION = process.env.appVersion;
export const GOOGLE_MAPS_KEY = env.googleMapKey;
export const CAPTCHA_KEY = env.captchaKey;
export const PRICES_PDF_URL = `${env.apiUrl}/web/files/type-11/CES_cenik_partner.pdf`;
export const MY_STAY_WEB = env.myStay;

// app instance cz | sk
export const INSTANCE = env.instance;
export const INSTANCE_CZ = env.instance === 'cz';
export const INSTANCE_SK = env.instance === 'sk';

// app lang (locale identifier: cs, sk)
export const DEFAULT_LANG = env.defaultLang;
