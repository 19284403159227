// @flow

import { EnvConfig } from "../flow/config.flow";

let devEnv = {};

if (process.env.NODE_ENV !== 'production') {
  const instance = 'sk';
  const defaultLang = instance === 'sk' ? 'sk' : 'cs';
  devEnv = {
    apiUrl: instance === 'sk' ? `https://web-api.gcmock.sk` : `https://web-api.gcdev.cz`,
    envType: 'develop',
    webUrl: '',
    googleMapKey: 'AIzaSyDiRmk6VDCUrMMbzyHYM-5Lg44Ev5FSjLI',
    instance,
    defaultLang,
  };
}

const envs = {
  auto: {
    envType: 'production',
    googleMapKey: 'AIzaSyD-kOWBNdjRfFjHYAQwFhm1pAutnPcAp4w', // all non-prod envs
    captchaKey: '6Lc_v4UqAAAAAKH-jiLKsD7g1tWwDn-Xz_3wNoIR',
  },
  server: {
    envType: 'production',
    apiUrl: 'https://web-api.gcdev.cz',
    webUrl: 'https://www.gcdev.cz',
    googleMapKey: 'OBSOLETE_SERVER_RENDER',
  },
  def: { // default env config
    googleMapKey: 'AIzaSyAwudQTXCdxRdA4A0pe5yMtCAfxuajm7NI', // development key
    captchaKey: '6Lf3vokUAAAAAI_maLLhjFZ296Jc0NbQlATTcySr',
  },
  gustokarta: {
    // googleMapKey: 'AIzaSyBlu0WsTDMYInE6YKL--ujyCHS5zlbifxE', // legacy key
    googleMapKey: 'AIzaSyDiRmk6VDCUrMMbzyHYM-5Lg44Ev5FSjLI', // somm.cz key
    // googleMapKey: 'AIzaSyDpqFq8fr10yU-PkgqWZTK_LS16NMFs8UU', // somm.cz Portal key
  },
  mojeup: {
    apiUrl: 'https://web-api.gustokarta.sk',
    googleMapKey: 'AIzaSyD-kOWBNdjRfFjHYAQwFhm1pAutnPcAp4w', // all non-prod envs
    captchaKey: '6Lc_v4UqAAAAAKH-jiLKsD7g1tWwDn-Xz_3wNoIR',
  }
};

export default function autodetectEnv(): EnvConfig {
  if (process.env.NODE_ENV === 'test') {
    return Object.assign({}, envs.def, { apiUrl: null, envType: 'test', instance: 'cz', defaultLang: 'cs' });
  }

  if (window.location.hostname === 'localhost' && process.env.NODE_ENV !== 'production') {
    return Object.assign({}, envs.def, devEnv, { webUrl: 'external-web-url/' });
  }

  const parts = window.location.host.split('.');

  if (!['registrace', 'registracia', 'kontaktnyformular'].includes(parts[0]) && parts[0] !== 'cesw') {
    throw new Error(`Invalid URL at ${window.location.host}`);
  }

  parts[0] = 'web-api';

  const topDomain = parts[parts.length - 1];

  if (!['cz', 'sk'].includes(topDomain)) {
    // eslint-disable-next-line
    console.warn(`TLD not recognized (${topDomain}, ${window.location.host}) falling back to .cz`);
  }

  const instance = ['cz', 'sk'].includes(topDomain) ? topDomain.toLowerCase() : 'cz';
  const defaultLang = instance === 'sk' ? 'sk' : 'cs';
  const autoApiUrl = `${window.location.protocol}//${parts.join('.')}`;

  const myStay = parts[0] === 'kontaktnyformular';

  parts[0] = 'www';
  const webUrl = `${window.location.protocol}//${parts.join('.')}`;

  return Object.assign({}, envs.def, envs.auto, { apiUrl: autoApiUrl, webUrl, instance, defaultLang, myStay }, { ...envs[parts[1]] });
}
