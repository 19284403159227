import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getP, getLocale } from 'redux-polyglot';
import { Link } from 'react-router';
import $ from 'jquery';
import Header, { getNextLangIndex, LANGUAGES } from '../app/Header.react';
import Footer from '../app/Footer.react';
import Notifications from '../app/Notifications.react';
import { addCompanyByIc, changeField, resetCompany, showError } from '../../common/registration/actions';
import { toggleSpinner } from '../../common/app/actions';
import { startStickyHeader } from '../browserCommonFunctions';

export class HomePage extends React.Component {

  legacyRefs: {[ key:string ]: any} = {};

  static propTypes = {
    addCompanyByIc: PropTypes.func,
    changeField: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    registration: PropTypes.object,
    resetCompany: PropTypes.func,
    showError: PropTypes.func,
    toggleSpinner: PropTypes.func,
  };

  componentDidMount() {
    $('.js-scroll').click(() => {
      if (
        window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
        && window.location.hostname === this.hostname
      ) {
        let a = $(this.hash);
        a = a.length ? a : $(`[name='${this.hash.slice(1)}']`);
        if (a) {
          return $('html, body').animate({
            scrollTop: a.offset().top,
          }, 1e3);
        }
      }
    });

    this.props.changeField({ name: 'showError', value: false });

    setTimeout(() => {
      this.props.changeField({ name: 'showError', value: false });
      startStickyHeader();
    }, 4);
  }

  async onStartRegistration(e) {
    e.preventDefault();

    // if (this.legacyRefs["company_id"].value) {
    //   const icToSearch = (this.legacyRefs["company_id"].value).trim().replace(/\s/g, '');
    //
    //   if (!/[0-9]+/.test(icToSearch)) {
    //     this.props.changeField({ name: 'showError', value: true });
    //     this.props.changeField({ name: 'showErrorText', value: this.props.pErrors.t(1) });
    //     return false;
    //   }
    //
    //   this.props.toggleSpinner();
    //
    //   $.ajax({
    //     url: `${API_URL}${API_VERSION}/${this.props.currentLocale}/registration/search/${icToSearch}`,
    //     method: 'GET',
    //     success: (data) => {
    //       this.props.toggleSpinner();
    //       this.props.addCompanyByIc(data);
    //       this.props.router.push(tRouteLocale('/lang/registration/basic-data', this.props.currentLocale));
    //     },
    //     error: (jqXHR) => {
    //       this.props.toggleSpinner();
    //
    //       const errorCode = jqXHR.responseJSON ? 1 * jqXHR.responseJSON.code : 10;
    //       window.location.href = `${WEB_URL}${this.props.currentLocale}/${tRouteLocale('registration', this.props.currentLocale)}?errorCode=${errorCode}`;
    //     },
    //   });
    // } else {
    //   this.props.changeField({ name: 'showError', value: true });
    //   this.props.changeField({ name: 'showErrorText', value: this.props.p.t('entervalid')});
    // }

    return false;
  }

  render() {
    const { location, p, currentLocale } = this.props;
    const langIdx = getNextLangIndex(currentLocale);
    return (
      <div className="site site-fixed-header">
        <Notifications />
        <Header p={p} location={location} currentLocale={currentLocale}>
          <li>
            <Link to={`/${LANGUAGES[langIdx].lang}`}>{LANGUAGES[langIdx].label}</Link>
          </li>
          <li role="separator" className="divider" />
          <li>
            <a href={p.t('headerSignInUrl')}>
              <span
                className="glyphicon glyphicon-user offset-right hidden-xs hidden-sm"
                aria-hidden="true"
              />
              <span className="hidden-md">{p.t('headerSignIn')}</span>
            </a>
          </li>
        </Header>
        <main id="content" role="main" className="site-content">
          <section className="text-center">
            <form
              className={`
                well well-raised decoration decoration-brand-red
                decoration-left-bottom expand-xs-compact offset-bottom-none hidden-print
              `}
              onSubmit={e => this.onStartRegistration(e)}
            >
              <h3 className="h2" dangerouslySetInnerHTML={{__html: p.t('acceptinggusto')}}></h3>
              {/*<div className="expand-xs-compact">*/}
              {/*  <div className="row offset-top-large offset-bottom-large">*/}
              {/*    <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">*/}
              {/*      <label htmlFor="company_id" className="sr-only"></label>*/}
              {/*      <div*/}
              {/*        className={*/}
              {/*          classnames(*/}
              {/*            'input-group-responsive',*/}
              {/*            {*/}
              {/*              'has-error': this.props.registration.showError &&*/}
              {/*              this.props.registration.showErrorText.includes('platné'),*/}
              {/*            }*/}
              {/*          )*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <input*/}
              {/*          type="text"*/}
              {/*          id="company_id"*/}
              {/*          ref={r => this.legacyRefs["company_id"] = r}*/}
              {/*          className="form-control form-control-flat"*/}
              {/*          placeholder={p.t('inputregistrationid')}*/}
              {/*        />*/}
              {/*        <button type="submit" className="btn btn-accent btn-responsive">*/}
              {/*          {p.t('proceed')}*/}
              {/*          <span*/}
              {/*            className={`*/}
              {/*              glyphicon glyphicon-menu-right icon-shift-down offset-left-large*/}
              {/*            `}*/}
              {/*            aria-hidden="true"*/}
              {/*          />*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <p className="help-block" dangerouslySetInnerHTML={{__html: p.t('pleasecontactfirst')}}>
              </p>
            </form>
          </section>
        </main>
        <Footer p={p} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registration: state.registration,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'home' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
});

const mapDispatchToProps = ({
  addCompanyByIc, toggleSpinner, changeField, resetCompany, showError
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
