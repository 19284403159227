import React, { PureComponent } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logoImage from '../../assets/images/up-logo.jpg';

export const LANGUAGES = [
  { lang: 'sk', label: 'Slovenčina'},
  { lang: 'en', label: 'English'},
];

export const LANGUAGES_SK = [
  { lang: 'sk', label: 'Slovenčina'},
  { lang: 'en', label: 'English'},
];

export const getNextLangIndex = (currentLocale, _langs) => {
  const langs = _langs || LANGUAGES;
  let langIdx = langs.findIndex(l => l.lang === currentLocale);

  langIdx++;

  if (langIdx >= langs.length) {
    langIdx = 0;
  }

  return langIdx;
};

class Header extends PureComponent {

  render() {
    const { title, noLink } = this.props;
    const titleElement = title && <h1 className="navbar-brand-subtitle">{title}</h1>;

    return (
      <header id="top" role="banner" className="site-header hidden-print">
        <Navbar className="header js-header" staticTop collapseOnSelect>
          <Navbar.Header>
            <Navbar.Toggle>
              <span className="navbar-toggle-text pull-left text-uppercase">Menu</span>
              <span className="navbar-toggle-icon pull-right">
                <span className="glyphicon glyphicon-menu-hamburger" id="menu-toggle" aria-hidden="true" />
              </span>
            </Navbar.Toggle>
            <Navbar.Brand>
              {noLink ? <div>
                <img
                  src={logoImage}
                  alt="MojeUp"
                />
                {titleElement}
              </div> : <a href="https://www.mojeup.sk">
                <img
                  src={logoImage}
                  alt="MojeUp"
                />
                {titleElement}
              </a>}
            </Navbar.Brand>
            </Navbar.Header>
          <Navbar.Collapse id="navigation">
            <Nav pullRight>
              {this.props.children}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <noscript>
          <div className="notification hidden-print active">
            <div className="alert alert-danger fade in" role="alert">
              <span className="glyphicon glyphicon-exclamation-sign offset-right" aria-hidden="true" />
              Je nám líto, ale bez JavaScriptu nemůže naše aplikace fungovat.
            </div>
          </div>
        </noscript>
      </header>
    );
  }
}

export default Header;
