// @flow
import React from 'react';
import { FormGroup, HelpBlock, Checkbox } from 'react-bootstrap';

type FieldGroupValue = boolean;

type Props = {
	id?: string;
	name: string;
	value: FieldGroupValue;
	label?: string;
	error?: string;
	onChange: (value: FieldGroupValue, name: string) => void;
};

type State = {
};

export default class CheckboxField extends React.Component<Props, State> {
	state = {
	};

	static defaultProps = {
		error: undefined,
	};

	handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e.target.checked, e.target.name);
		}
	}

	render() {
		const { id, name, value, type, label, children, error, onChange, ...restProps } = this.props;

		return (
			<FormGroup
			controlId={id || name}
			validationState={error ? 'error' : null}
		>
    	<Checkbox name={name} onChange={this.handleChange} checked={value} {...restProps}>{label || children}</Checkbox>
			{error && <HelpBlock>{error}</HelpBlock>}
		</FormGroup>
		);
	}
}
