import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getP, getLocale } from 'redux-polyglot';
import Helmet from 'react-helmet';
import { Col, Row, Grid } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmail } from 'validator';

// import { tRouteLocale } from '../../browser/createRoutes';
import { myStaySend } from './actions';
import Header, { getNextLangIndex, LANGUAGES_SK } from '../app/Header.react';
import Footer from '../app/Footer.react';
import Notifications from '../app/Notifications.react';
import { toggleSpinner } from '../../common/app/actions';
import FieldGroup from '../app/components/FieldGroup';
import {CAPTCHA_KEY} from "../browserConfig";

const initialState = {
  finished: false,
  submitted: false,
  errors: {},
  tid: '',
  ic: '',
  so: '',
  bank: '',
  email: '',
  phone: '',
  captcha: null,
};

class AcceptanceMethodsFormContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    toggleSpinner: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState() {
    const { p } = this.props;
    return {
      ...initialState,
      phone: p.t('phoneprefix'),
    };
  }

  handleSubmit = (e) => {
    const { p } = this.props;
    e.preventDefault();
    const errors = this.validate();

    this.setState({ errors, submitted: true, });

    if (Object.keys(errors).length) {
      console.log('errors', errors);
      return;
    }

    const data =  {
      type: 'acc-methods',
      tid: this.state.tid,
      ic: this.state.ic,
      so: this.state.so,
      bank: this.state.bank,
      email: this.state.email,
      phone: this.state.phone,
      token: this.state.captcha,
    };

    this.props.myStaySend(data).then(act => {
      if (act.error) {
        this.setState({
          errors: {
            submit: p.t('formsenderror'),
          },
      });
        return;
      }

      this.setState({ finished: true });
    });
  }

  handleReset = () => {
    this.setState(this.getInitialState());
  }

  handleChange = (updt) => {
    this.setState(updt, () => {
      if (this.state.submitted) {
        const errors = this.validate();
        this.setState({ errors });
      }
    });
  }

  validate() {
    const { p } = this.props;
    const values = this.state;
    const errors = {};

    if (values.tid.length < 1) {
      errors.tid = p.t('fieldismandatory');
    }

    if (values.ic.length < 1) {
      errors.ic = p.t('fieldismandatory');
    }

    if (values.so.length < 1) {
      errors.so = p.t('fieldismandatory');
    }

    if (values.bank.length < 1) {
      errors.bank = p.t('fieldismandatory');
    }

    if (values.email.length < 5 || !isEmail(values.email)) {
      errors.email = p.t('fieldismandatory');
    }

    if (values.phone.length < 1 || ! /^(\+?42[01])? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(values.phone)) {
      errors.phone = p.t('fieldismandatory');
    }

    if (!values.captcha) {
      errors.captcha = p.t('captchaismandatory');
    }

    // if (!values.gdprterms) {
    //   errors.gdprterms = p.t('fieldismandatory');
    // }

    return errors;
  }

  renderFinished() {
    const { p } = this.props;
    return (
      <section className="text-center" style={{minHeight: '50vh', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <h3 className="h2">{p.t('thankyoutext1')}</h3>
      </section>
    );
  }

  renderForm() {
    const { p } = this.props;

    return (
      <section className="section-content">
      <form
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
      >
      <Grid>
        <Row>
          <Col xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{paddingBottom: 30, maxWidth: 550}}>
            <h3 className="h2">
              {p.t('acc-methods.fillinform1')}
            </h3>
            <p>{p.t('acc-methods.fillinform2')}</p>
            <FieldGroup
              name="tid"
              label={p.t("tid")}
              value={this.state.tid}
              onChange={val => this.handleChange({tid: val})}
              error={this.state.errors.tid}
            />
            <FieldGroup
              name="ic"
              label={p.t("ic")}
              value={this.state.ic}
              onChange={val => this.handleChange({ic: val})}
              error={this.state.errors.ic}
            />
            <FieldGroup
              name="email"
              label={p.t("email")}
              value={this.state.email}
              onChange={val => this.handleChange({email: val})}
              error={this.state.errors.email}
            />
            <FieldGroup
              name="so"
              label={p.t("posSo")}
              value={this.state.so}
              onChange={val => this.handleChange({so: val})}
              error={this.state.errors.so}
            />
            <FieldGroup
              name="bank"
              label={p.t("posBank")}
              value={this.state.bank}
              onChange={val => this.handleChange({bank: val})}
              error={this.state.errors.bank}
            />
            {/* <div className={classnames("form-group", { "has-error": this.state.errors.so })}>
              <label className="control-label-floating active">{p.t('posSo')}</label>
              <Select
                className="Select-form"
                resetValue=""
                options={POS_SO_LIST}
                searchable={false}
                placeholder={p.t('select')}
                value={this.state.so}
                onChange={value => this.handleChange({ so: value && value.value })}
                id="so" />
              {this.state.errors.so && <div className="help-block">{this.state.errors.so}</div>}
            </div>
            <div className={classnames("form-group", { "has-error": this.state.errors.bank })}>
              <label className="control-label-floating active">{p.t('posBank')}</label>
              <Select
                className="Select-form"
                resetValue=""
                options={POS_BANKS_LIST}
                searchable={false}
                placeholder={p.t('select')}
                value={this.state.bank}
                onChange={value => this.handleChange({ bank: value && value.value })}
                id="bank" />
              {this.state.errors.bank && <div className="help-block">{this.state.errors.bank}</div>}
            </div> */}
            <FieldGroup
              name="phone"
              label={p.t("phone")}
              maxLength={20}
              value={this.state.phone}
              onChange={val => this.handleChange({phone: val})}
              error={this.state.errors.phone}
            />
            {/* <CheckboxField
              name="gdprterms"
              value={this.state.gdprterms}
              onChange={val => this.handleChange({gdprterms: val})}
              error={this.state.errors.gdprterms}
            >
              <span>
                {p.t('gdprtext1')}
                <a href={p.t('gdprtermslink')} rel="noopener noreferrer" target="_blank" className="link">
                  {p.t('gdprtextlink')}
                </a>
                {p.t('ibm-mothers.gdprtext2')}
              </span>
            </CheckboxField> */}
            <div className={this.state.errors.captcha && "has-error"} style={{display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', flexDirection: 'column'}}>
              <ReCAPTCHA
                sitekey={CAPTCHA_KEY}
                onChange={val => this.setState({captcha: val})}
              />
              {this.state.errors.captcha && <div className="help-block">{this.state.errors.captcha}</div>}
            </div>
            {this.state.errors.submit && <div className="has-error"><h3 className="text-center help-block">{this.state.errors.submit}</h3></div>}
          </div>
          </Col>
        </Row>
        <hr className="offset-top-none offset-bottom-large-xs-max offset-bottom-large" />
        <Row>
          <Col md={12} style={{display: 'flex', justifyContent: 'space-around'}}>
            <button type="reset" className="btn btn-accent btn-accent-outline btn-lg btn-icon-left">
              <span className="glyphicon glyphicon-remove" aria-hidden="true" />
              {p.t('reset')}
            </button>
            <button type="submit" className="btn btn-accent btn-lg">
              <span className="btn-icon-right">
                {p.t('submit')} <span className="glyphicon glyphicon-send" aria-hidden="true" />
              </span>
            </button>
          </Col>
        </Row>
      </Grid>
      </form>
    </section>
    );
  }

  render() {
    const { location, p, currentLocale } = this.props;
    const langIdx = getNextLangIndex(currentLocale, LANGUAGES_SK);
    const nextLang = LANGUAGES_SK[langIdx].lang;
    const langLinks = {
      cs: 'metody-akceptace',
      sk: 'metody-akceptacie',
      en: 'acceptance-methods',
    };

    return (
      <div className="site site-fixed-header">
        <Helmet title={p.t('mystayquestionnaire')}>
          {<meta name="description" content={p.t('metadescription')} />}
        </Helmet>
        <Notifications />
        <Header title={p.t('mystayquestionnaire')} p={p} location={location} currentLocale={currentLocale} noLink>
          <li>
            <Link to={`/${nextLang}/${langLinks[nextLang] || langLinks.sk}`}>{LANGUAGES_SK[langIdx].label}</Link>
          </li>
          <li role="separator" className="divider" />
          <li>
            <a href={p.t('headerSignInUrl')}>
              <span
                className="glyphicon glyphicon-user offset-right hidden-xs hidden-sm"
                aria-hidden="true"
              />
              <span className="hidden-md">{p.t('headerSignIn')}</span>
            </a>
          </li>
        </Header>
        <main id="content" role="main" className="site-content bg-gray-lighter">
          {this.state.finished ? this.renderFinished() : this.renderForm()}
        </main>
        <Footer p={p} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.mystay,
  currentLocale: getLocale(state),
  p: getP(state, { polyglotScope: 'mystay' }),
  pErrors: getP(state, { polyglotScope: 'errors' }),
});

const mapDispatchToProps = ({
  toggleSpinner, myStaySend
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceMethodsFormContainer);
